<template>
    <div>
        <v-autocomplete
            :items="flows"
            v-model="info.args.flowId"
            label="选择工作流"
            item-text="name"
            item-value="id"
            :no-data-text="noDataText"
            messages="* 仅可使用自己的工作流。若要使用他人创建的工作流，请先另存为自己的副本以后，方可使用。"
        >
        </v-autocomplete>
    </div>
</template>

<script>
import { getFlows } from "../../store/query";

export default {
    props: {
        info: Object
    },
    data () {
        return {
            flows: [],
        }
    },
    computed: {
        noDataText () {
            if (!this.vuex_userinfo) return '请先登录'
            return '没有符合要求的工作流'
        }
    },
    async mounted () {
        let fids = this.vuex_flowHistory
        let flows = await getFlows(fids)
        flows = flows.filter(x=>{
            if (!x.creator_id) return false
            if (!this.vuex_userinfo) return false
            return x.creator_id === this.vuex_userinfo.id
        })
        this.flows = flows
    }
}
</script>